/* Mixins */
@mixin line-clamp($lineheight, $lines) {
  line-height: $lineheight !important;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin line-clamp-fixed($fontsize, $lineheight, $lines) {
  font-size: $fontsize;
  line-height: $lineheight !important;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* IGNORELINE */
  height: $fontsize * $lineheight * $lines; /* Fallback for non-webkit */
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Homepage sorting fix */
body.homepage .homeFeaturesWrapper {
  display: flex;
  flex-direction: column;
  // Define the number of classes you want to generate
  $number-of-classes: 12;
  // Create a loop to generate the classes
  @for $i from 1 through $number-of-classes {
    .homeBox#{$i} {
      order: #{$i};
    }
  }
}

/* Footer sorting fix */
.pageFooter {
  display: flex;
  flex-wrap: wrap;
  // Define the number of classes you want to generate
  $number-of-classes: 12;
  // Create a loop to generate the classes
  @for $i from 1 through $number-of-classes {
    .footerBox#{$i} {
      order: #{$i};
      margin: 0;
    }
  }
}

/* Admin Bar */
.adminBar,
.adminBarEdit {
  width: 100%;
  flex-wrap: wrap;
  height: auto;
}
.adminBar {
  padding-bottom: 0;
  .content {
    font-size: $font-size-small;
  }
}
.adminBarEdit {
  .content {
    font-size: $font-size-small;
  }
  .editPostCat {
    flex-wrap: wrap;
    .postApproval {
      padding: 15px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      .formQuestion {
        margin: 0;
      }
    }
  }
  .editPostDetails {
    flex-wrap: wrap;
    gap: 20px;
    .button {
      margin: 0;
    }
  }
}

/* Global */
html {
  scroll-behavior: smooth;
}
body {
  top: 0 !important;
}
.mapouter,
.embed-container {
  margin-bottom: 1rem;
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}
.cta-button, .button, button, .readMore, .addToCalender {
  &:hover {
    color: $white;
    text-decoration: none;
    background-color: $hover-primary;
    border-color: $hover-primary;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: $font-mobile-h1;
  }
  h2 {
    font-size: $font-mobile-h2;
  }
  h3 {
    font-size: $font-mobile-h3;
  }
  h4 {
    font-size: $font-mobile-h4;
  }
  h5 {
    font-size: $font-mobile-h5;
  }
}

/* Homepage Only Stylings --------------------- */
body.homepage {
  margin-top: 0 !important;
  #pageHeader {
    background: transparent;
    transition: background $transition-duration $transition-curve;
    &.sticky {
      background: $bg-black;
    }
  }
}

/* Header ------------------------------------- */
#pageHeader {
  // visible menu
  .headerContent {
    padding: 30px;
    justify-content: space-between;
    // auto hide search
    .header-search {
      display: none;
    }
    .mainCallToAction {
      gap: 35px;
      margin: 0;
      // auto hide socials
      .socialIcons {
        display: none;
      }
    }
  }
  // replica menu
  #replicaMenu {
    position: relative;
    gap: 35px;
    display: flex;
    list-style: none;
    align-items: center;
    &:after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 47.5px;
      background-image: url($assets-path + "line.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .level1 {
      position: relative;
      &:hover {
        > a {
          text-decoration: none;
          &:before {
            width: 100%;
          }
        }
        > ul {
          opacity: 1;
          z-index: 1;
          visibility: visible;
          transform: none;
        }
      }
      > a {
        display: block;
        position: relative;
        padding: 0.25rem 0;
        color: $white;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 1px;
          background: $white;
          transition: width $transition-duration $transition-curve;
        }
        &:after {
          color: $white;
          margin-left: 5.5px;
        }
      }
      > ul {
        position: absolute;
        top: 100%;
        left: -15px;
        z-index: 0;
        opacity: 0;
        visibility: hidden;
        background: $bg-colour;
        border-radius: 10px;
        box-shadow: 2px 2px 10px 0px rgba($black, 0.05);
        transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s ease;
        transform: translate(0, 20px);
        padding: 10px;
        width: 100%;
        min-width: max-content;
        pointer-events: auto;
        list-style: none;
        display: flex;
        flex-direction: column;
        li a {
          height: 100%;
          padding: 10px;
          position: relative;
          border-radius: 4px;
          color: $body-colour;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
          display: flex;
          transition: background $transition-duration $transition-curve;
          &:hover {
            text-decoration: none;
            background: $brand-primary;
          }
        }
        li > ul {
          position: absolute;
          top: 0;
          left: calc(100% - 15px);
          z-index: 0;
          opacity: 0;
          visibility: hidden;
          border-radius: 0 10px 10px 0;
          background: $bg-colour;
          box-shadow: 2px 2px 10px 0px rgba($black, 0.05);
          transition: opacity 0.2s ease, transform 0.2s ease,
            visibility 0.2s ease;
          transform: translate(20px, 0);
          padding: 10px;
          width: 100%;
          min-width: max-content;
          pointer-events: auto;
          display: flex;
          flex-direction: column;
        }
        li:hover > ul {
          opacity: 1;
          z-index: 1;
          visibility: visible;
          transform: none;
        }
      }
    }
    // responsives
    @media (max-width: 767px) {
      display: none;
    }
  }
  // burger menu
  .menuMainAlt {
    position: relative;
    height: 50px;
    width: 50px;
    margin: 0;
    padding: 0;
    font-size: 0;
    border: none;
    border-radius: 50%;
    transition: background $transition-duration $transition-curve;
    &:before {
      content: "";
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url($assets-path + "burger-menu.svg");
      background-repeat: no-repeat;
      background-position: center center;
      transition: all $transition-duration $transition-curve;
    }
    &:hover,
    &:focus {
      background: $hover-primary;
      &:before {
        filter: invert(1);
      }
    }
    &.active {
      background: $brand-primary;
      &:before {
        background-image: url($assets-path + "burger-close.svg");
      }
      &:hover,
      &:focus {
        background: $hover-primary;
      }
    }
  }
  // fly out nav
  #menuMain {
    padding: 0;
    // interact menu
    #interactMenu {
      border-bottom: 1px dashed $brand-primary;
      margin: 0 30px 20px;
      padding: 30px 0;
      gap: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .mainCallToActionButtons {
        gap: 35px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &:after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 47.5px;
          background-image: url($assets-path + "line.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      .logo-burger .mainLogo {
        display: none;
      }
    }
    // search bar
    .header-search {
      display: block;
      padding: 0;
      .searchContainer {
        padding: 20px 30px;
        gap: 15px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        input {
          font-size: $font-size-large;
          outline: none;
          border-bottom: 1px solid transparent;
          transition: border $transition-duration $transition-curve;
          &:focus {
            border-color: $white;
          }
        }
        button[type="submit"] {
          flex: 0 0 $header-search-button-width;
          background-image: url($assets-path + "search.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          &:before {
            display: none;
          }
        }
      }
    }
    // top level menu
    .topLevel {
      // level 1
      .level1 {
        // hide first 3 menus
        @media (min-width: 768px) {
          &.menu1,
          &.menu2,
          &.menu3 {
            display: none;
          }
        }
        // general link stylings
        > a {
          position: relative;
          padding: 10px 30px;
          line-height: 1.4;
          font-size: $font-size-h3;
          font-weight: $font-weight-bold;
          &:hover:after {
            color: $brand-primary;
          }
          &.active {
            color: $brand-primary;
            &:before {
              height: 28.5px;
            }
            &:after {
              color: $brand-primary;
            }
          }
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 5px;
            height: 0px;
            transform: translateY(-50%);
            background: $brand-primary;
            transition: height $transition-duration $transition-curve;
          }
        }
      }
      // sub menus
      .subMenu a {
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: transparent;
        text-underline-offset: 0.1em;
        transition: text-decoration $transition-duration $transition-curve;
        &:hover {
          text-decoration-color: $white;
        }
      }
    }
    // social icons
    .mainCallToAction .socialIcons {
      border-top: 1px dashed $brand-primary;
      margin: 30px 30px 0;
      padding: 37.5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 0.5px;
          background: $brand-primary;
          transition: width $transition-duration $transition-curve;
        }
        a {
          transition: transform $transition-duration $transition-curve;
        }
        &:hover,
        &:focus {
          &:before {
            width: 100%;
          }
          a {
            transform: translateY(-9.5px);
          }
        }
      }
    }
    // responsives
    @media (max-width: 767px) {
      #interactMenu {
        gap: 0;
        border: none;
        flex-wrap: wrap;
        margin-bottom: 0;
        .logo-burger {
          border-bottom: 1px dashed $brand-primary;
          margin-bottom: 30px;
          padding-bottom: 30px;
          flex: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .mainLogo {
            display: block;
            flex: 0 0 $logo-mobile-width;
          }
        }
        .mainCallToActionButtons {
          order: 1;
          flex: 100%;
          &:after {
            display: none;
          }
          a {
            display: block;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

/* Footer ------------------------------------- */
#pageFooterWrapper {
  // default wrapper
  .pageFooter {
    max-width: $smaller-max-width;
    width: $container-grid-size;
    margin: 0 auto;
    padding: 50px 0;
    gap: 50px;
    justify-content: space-between;
    h1,
    h2,
    h3 {
      font-size: $font-size-h4;
      line-height: 1.266;
      margin-bottom: 30px;
    }
  }
  // newsletter
  .NewsletterSignUp {
    max-width: 450px;
    form {
      label {
        display: block;
        margin-top: 15px;
        font-weight: $font-weight-bold;
      }
      input {
        margin: 0;
        width: 100%;
        color: $white;
        padding: 15px;
        border-radius: 10px;
        background: rgba($white, 0.09);
        border: 1px solid $white;
      }
      button {
        margin: 20px 0 0;
        padding: 13px 25px;
        &:hover {
          color: $white;
          background-color: $hover-primary;
          border-color: $hover-primary;
        }
      }
    }
  }
  // links
  .FooterLinks {
    a {
      color: $white;
      line-height: 2.188;
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: transparent;
      text-underline-offset: 0.1em;
      transition: text-decoration $transition-duration $transition-curve;
      &:hover {
        text-decoration-color: $white;
      }
    }
  }
  // contact
  .FooterContact {
    gap: 35px;
    display: flex;
    flex-direction: column;
    .wrapper li {
      gap: 1rem;
      display: flex;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translateY(3px);
      }
      // phone
      &:nth-of-type(1)::before {
        background-image: url($assets-path + "call.svg");
      }
      // email
      &:nth-of-type(2)::before {
        background-image: url($assets-path + "email.svg");
      }
      // address
      &:nth-of-type(3)::before {
        background-image: url($assets-path + "marker.svg");
      }
    }
  }
  // socials
  .FooterSocials {
    .socialIcons {
      gap: 40px;
      display: flex;
      align-items: center;
      li {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 0.5px;
          background: $brand-primary;
          transition: width $transition-duration $transition-curve;
        }
        a {
          transition: transform $transition-duration $transition-curve;
          &:before {
            color: $icons;
            transition: color $transition-duration $transition-curve;
          }
        }
        &:hover,
        &:focus {
          &:before {
            width: 100%;
          }
          a {
            transform: translateY(-9.5px);
            &:before {
              color: $brand-primary;
            }
          }
        }
      }
    }
  }
  // copyright
  .FooterCopyright {
    margin: 0;
    padding: 9.5px 0;
    background: $brand-secondary;
    * {
      color: $white;
      margin: 0 auto;
      width: $container-grid-size;
      max-width: $smaller-max-width;
      font-size: $font-size-small;
    }
  }
  // responsives
  @media (max-width: 1199px) {
    .NewsletterSignUp {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .FooterContact  {
      gap: 75px;
      flex-direction: row;
    }
  }
}

/* Home Carousel ------------------------------ */
body.homepage .carouselImageMultiple {
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 210px;
  }
  // top overlay
  &:before {
    top: 0;
    background: linear-gradient(180deg, rgba($black, 0.8) 0%, transparent 100%);
  }
  // bot overlay
  &:after {
    bottom: 0;
    background: linear-gradient(180deg, transparent 0%, $black 100%);
  }
  // carousel slides
  #carouselSlides {
    .carouselSlideTitle {
      height: 890px;
      background: $black;
      img {
        width: 100%;
        height: 100%;
        opacity: 0.8;
        object-fit: cover;
      }
    }
    .carouselSlideHeading {
      font-family: $display-font-family;
      font-weight: $font-weight-bold;
      a {
        text-decoration: none;
      }
    }
  }
  // carousel controls
  .carouselControls {
    // next arrow
    li:first-of-type {
      margin-right: 30px;
    }
    // prev arrow
    li:last-of-type {
      margin-left: 30px;
    }
    a {
      position: relative;
      z-index: 2;
      border: 1px solid $white;
      &:hover {
        background: $brand-primary;
        border-color: $brand-primary;
      }
    }
  }
  // responsives
  @media (max-width: 767px) {
    #carouselSlides {
      .carouselDetailWrapper {
        padding: 0 30px;
      }
      .carouselSlideTitle {
        height: auto;
        img {
          min-height: 445px;
        }
      }
      .carouselSlideHeading {
        font-size: $mobile-display-3;
      }
    }
    .carouselControls {
      align-items: flex-end;
      margin-bottom: 30px;
    }
  }
} 

/* Home Intro --------------------------------- */
.homeIntroWrapper .homeIntro {
  width: $container-grid-size;
  margin: 0 auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      flex: 1;
      padding: 30px 50px;
      background: $bg-light;
      border-radius: 8px;
      a {
        gap: 20px;
        display: flex;
        text-decoration: none;
        transition: color $transition-duration $transition-curve;
        &:before {
          content: "";
          display: inline-block;
          width: 22px;
          height: 22px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          transform: translateY(2px);
        }
        &:hover {
          color: $link-colour;
        }
      }
      // opening time
      &:nth-of-type(1) a:before {
        background-image: url($assets-path + "clock.svg");
      }
      // donation welcome
      &:nth-of-type(2) a:before {
        background-image: url($assets-path + "no-charge.svg");
      }
    }
  }
  // responsives
  @media (max-width: 991px) {
    ul li {
      flex: 100%;
    }
  }
  @media (max-width: 767px) {
    ul li {
      padding: 30px;
    }
  }
}

/* Events Feed & News Feed -------------------- */
.homefeaturecategory-eventstitle,
.homefeaturecategory-newstitle {
  position: relative;
  margin: 0 auto 90px;
  width: $container-grid-size;
  max-width: $container-max-width;
  // title wrapper
  .titleWrapper > *:last-child {
    position: relative;
    margin-bottom: 0;
    gap: 50px;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: inline-block;
      flex: 1;
      height: 1px;
      background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
      background-size: 5px;
    }
  }
  // carousel feed
  .homeFeed {
    margin-top: 50px;
    // the title
    .feedsTitle {
      display: none;
    }
    // the carousel
    .feedList {
      width: auto;
      margin: 0 -40px;
      max-width: max-content;
      // same height slides
      .slick-track{
        display: flex;
        .slick-slide {
          height: auto;
        }
      }
      // the slide
      .feedItem {
        background: $bg-colour;
        margin: 0 40px;
        display: flex;
        flex-direction: row;
        &:hover [class*="DetailsWrapper"] .readMore:before{
          width: 100%;
        }
        // title
        .feedTitle {
          margin-bottom: 0;
          a {
            text-decoration: none;
            font-weight: $font-weight-bold;
          }
        }
        // image
        [class*="image-link"] {
          flex: 1;
          aspect-ratio: 32 / 40;
          .banner {
            border-radius: 0;
          }
        }
        // description
        [class*="DetailsWrapper"] {
          flex: 1;
          padding: 40px;
          gap: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // read more
          .readMore {
            position: relative;
            display: block;
            margin: 30px 0 0;
            padding: 0;
            color: $body-colour;
            font-weight: $font-weight-bold;
            width: fit-content;
            border: none;
            background: transparent;
            &:before {
              content: "";
              position: absolute;
              bottom: -2.5px;
              left: 0;
              width: 0;
              height: 2px;
              background: $brand-primary;
              transition: width $transition-duration $transition-curve;
            }
            &:after {
              content: "\f054";
              display: inline-block;
              font-family: "FontAwesome";
              font-size: 10px;
              margin-left: 6px;
              transform: translateY(-1px);
            }
          }
        }
        // the date
        .postFooterWrapper {
          padding: 0;
          margin-bottom: auto;
          background: none;
          font-size: 18px;
          font-weight: $font-weight-bold;
          color: $event;
          width: fit-content;
          .associatedStartDate {
            opacity: 1;
            margin: 0;
            &:before {
              display: none;
            }
            sup {
              display: none;
            }
          }
          .publishDetailsWrapper,
          .locationDetails {
            display: none !important;
          }
        }
      }
    }
    // carousel controls
    .navigator {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // wrapper box
      .slider-box {
        gap: 30px;
        display: flex;
        align-items: center;
      }
      // text label
      .slider-label {
        margin-bottom: 0;
        font-size: 13.6px;
      }
      // arrows and dots
      .slider-nav {
        gap: 30px;
        display: flex;
        align-items: center;
        .slick-arrow {
          width: 40px;
          height: 40px;
          margin: 0;
          padding: 0;
          outline: none;
          border-radius: 50%;
          border: 1px solid $brand-tertiary;
          background: transparent;
          &:hover {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
        .dot-wrapper ul {
          list-style: none;
          padding: 0;
          margin: 0;
          gap: 20px;
          display: flex;
          align-items: center;
          li {
            display: flex;
          }
          .slick-active button {
            background: $brand-tertiary;
            border-color: $brand-tertiary;
          }
          button {
            width: 10px;
            height: 10px;
            margin: 0;
            padding: 0;
            font-size: 0;
            border: 1px solid $brand-tertiary;
            border-radius: 50%;
            background: transparent;
            &:hover {
              background: $brand-primary;
              border-color: $brand-primary;
            }
          }
        }
      }
      // cta button
      > .button {
        margin: 0;
      }
    }
  }
  // responsives
  @media (max-width: 1199px) {
    .homeFeed .feedList {
      margin: 0 -15px;
      .feedItem {
        margin: 0 15px;
      }
    }
  }
  @media (max-width: 767px) {
    .titleWrapper > *:last-child {
      gap: 0;
      &:after {
        display: none;
      }
    }
    .homeFeed {
      .feedList .feedItem {
        flex-wrap: wrap;
        [class*="image-link"] {
          flex: 100%;
          height: 180px;
          aspect-ratio: 32 / 18;
        }
        .feedItemDetailsWrapper {
          padding: 20px;
        }
        .postFooterWrapper {
          top: 200px;
          right: 20px;
        }
      }
      .navigator {
        gap: 30px;
        flex-direction: column;
        .slider-box {
          gap: 15px;
          flex-direction: column;
        }
        > .button {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

/* Warm Welcome ------------------------------- */ 
.homefeaturecategory-welcometitle {
  position: relative;
  margin: 0 auto 90px;
  width: $container-grid-size;
  max-width: $smaller-max-width;
  // title wrapper
  .titleWrapper > *:last-child {
    position: relative;
    margin-bottom: 0;
    gap: 50px;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: inline-block;
      flex: 1;
      height: 1px;
      background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
      background-size: 5px;
    }
  }
  // welcome feed
  .WarmWelcomeFeed {
    margin-top: 50px;
    // the title
    .feedsTitle {
      display: none;
    }
    // the carousel
    .feedList {
      gap: 80px;
      display: flex;
      flex-wrap: wrap;
      // the slide
      .feedItem {
        flex: 1;
        margin: 0;
        &:hover [class*="DetailsWrapper"]:after {
          opacity: 1;
        }
        // image
        [class*="image-link"] {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .banner {
            border-radius: 0;
          }
        }
        // description
        [class*="DetailsWrapper"] {
          position: relative;
          padding: 40px;
          display: flex;
          min-height: 400px;
          flex-direction: column;
          justify-content: flex-end;
          &:before,
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, transparent 0%, rgba($black, 0.8) 100%);
            transition: opacity $transition-duration $transition-curve;
          }
          &:before {
            opacity: 1;
            height: 50%;
          }
          &:after {
            opacity: 0;
            height: 100%;
          }
          .feedTitle {
            position: relative;
            z-index: 1;
            font-size: $font-size-h4;
            margin-bottom: 10px;
            a {
              color: $white;
              text-decoration: none;
              &:after {
                content: "\f054";
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 14px;
                margin-left: 20px;
                transform: translateY(-2.5px);
              }
            }
          } 
          > p {
            position: relative;
            color: $white;
            z-index: 1;
          }
        }
      }
    }
  }
  // responsives
  @media (max-width: 991px) {
    .WarmWelcomeFeed .feedList .feedItem {
      flex: 100%;
    }
  }
  @media (max-width: 767px) {
    .titleWrapper > *:last-child {
      gap: 0;
      &:after {
        display: none;
      }
    }
    .WarmWelcomeFeed .feedList .feedItem [class*="DetailsWrapper"] {
      padding: 20px;
      &:after {
        opacity: 1;
      }
      .feedTitle {
        font-size: $font-mobile-h4;
      }
    }
  }
}

/* Impact Stats Section ----------------------- */
.homefeaturecategory-homeboximpactimage {
  position: relative;
  min-height: 590px;
  display: flex;
  align-items: center;
  // the overlay
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    width: 35%;
    height: 100%;
  }
  // overlay - left
  &:before {
    left: 0;
    background: linear-gradient(90deg, $black 0%, transparent 100%);
  }
  // overlay - right
  &:after {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, $black 100%);
  }
  // the image
  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  // the stats
  .homefeaturecategory-homeboximpactstats {
    position: relative;
    z-index: 1;
    width: $container-grid-size;
    max-width: $smaller-max-width;
    padding: 90px 0;
    margin: 0 auto;
    gap: 10vw;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    tbody {
      justify-content: space-between;
    }
    tr {
      max-width: 175px;
    }
    td:first-child {
      margin-bottom: 0.25rem;
      > * {
        line-height: 1.487;
      }
    }
  }
  // the blurb
  .homefeaturecategory-homeboximpactblurb {
    flex: 0 0 455px;
    max-width: 455px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }
  }
  // responsives
  @media (max-width: 1299px) {
    .homefeaturecategory-homeboximpactstats {
      tbody {
        gap: 0.25rem;
        flex-direction: column;
      }
      tr {
        max-width: 100%;
      }
    } 
  }
  @media (max-width: 991px) {
    .homefeaturecategory-homeboximpactstats {
      gap: 1rem;
      flex-direction: column-reverse;
      .homefeaturecategory-homeboximpactblurb {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}

/* Custom CTA Section ------------------------- */
.homefeaturecategory-homectatitle {
  position: relative;
  margin: 90px auto;
  padding: 50px 0;
  width: $container-grid-size;
  max-width: $smaller-max-width;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  // the overlay
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: $bg-light;
    border-radius: 10px;
  }
  // the title
  .title-wrapper {
    position: relative;
    max-width: 455px;
    flex: 1;
  }
  // the buttons
  .homefeaturecategory-homectabuttons {
    max-width: 780px;
    flex: 1;
    ul {
      list-style: none;
      padding: 30px 60px 30px 40px;
      margin: 0;
      gap: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      [class*="-button"] {
        white-space: nowrap;
        margin: 0;
      }
    }
  }
  // responsives
  @media (max-width: 1199px) {
    .homefeaturecategory-homectabuttons ul {
      gap: 30px;
      flex-wrap: wrap;
      li {
        flex: 100%;
      }
      [class*="-button"] {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    &:before {
      top: unset;
      right: unset;
      left: 50%;
      bottom: 0;
      width: calc(100% + 7.5%);
      height: 400px;
      transform: translateX(-50%);
    }
    .title-wrapper {
      flex: 100%;
    }
    .homefeaturecategory-homectabuttons {
      flex: 100%;
      ul {
        padding: 30px 0;
      }
    }
  }
}

/* Has Banner Layout -------------------------- */
body.page-has-banner:not(.listing-with-feature) {
  // banner image
  .carouselImageSingle {
    padding-top: 55vh;
    #carouselSlides {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      .carouselSlide {
        height: 60vh;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  } 
  // title and breadcrumb section
  #afterCarousel + .headerText {
    position: relative;
    margin: 0 auto;
    max-width: $post-max-width;
    width: $container-grid-size;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      width: calc(100% + 80px);
      height: calc(100% + 80px);
      transform: translate(-50%, -50%);
      background: $bg-colour;
      border-radius: 10px;
    }
    .title {
      margin-bottom: 0;
      font-size: $font-display-3;
      font-weight: $font-weight-base;
      font-family: $display-font-family;
    }
  }
  // post content
  .headerWrapper + .contentBlockWrapper {
    max-width: $post-max-width;
    width: $container-grid-size;
    margin: 80px auto;
  }
  .appealPostPage {
    .contentBlockWrapper:not(.appealActionsWrapper) {
      margin-top: 0;
      margin-bottom: 80px;
      width: $container-grid-size;
      max-width: $post-max-width;
    }
    .appealActionsWrapper {
      background: none;
      padding: 0;
      margin: 80px auto 40px;
      width: $container-grid-size;
      max-width: $post-max-width;
      .button {
        margin: 0;
      }
    }
    .postAside {
      display: none;
    }
  }
  // listings
  .listing .listedPost:not(.listedProduct) {
    &:not(.listedSearchResult):not(.listedMapItem) {
      [class*="image-link"] {
        aspect-ratio: 1 / 1;
      }
      [class^="listed"][class$="Text"] h2 {
        font-size: $font-size-h6;
      }
    }
    @media (max-width: 1024px) {
      width: calc((100% - 60px) / 2 - 1px);
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  // responsives
  @media (max-width: 767px) {
    .pageWrapper {
      overflow: hidden;
    }
    #afterCarousel + .headerText .title {
      font-size: $mobile-display-3;
    }
  }
}

/* No Banner Layout --------------------------- */
body.page-no-banner,
body.listing-with-feature {
  // Hide active slider - not part of design
  .carouselImageMultiple {
    display: none;
  }
  // title + breadcrumb section
  .pageWrapper .headerWrapper {
    position: relative;
    background: rgba($brand-primary, 0.4);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url($assets-path + "t-st-albans.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: calc(100% - 55px);
      @media (max-width: 767px) {
        opacity: 0.5;
        background-position: center;
      }
    }
    .headerText {
      position: relative;
      padding: 80px 0;
      margin: 0 auto;
      width: $container-grid-size;
      max-width: $container-max-width;
    }
  }
  // listing intro description
  .listing .contentBlockWrapper {
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;
    width: $container-grid-size;
    max-width: $container-max-width;
    .listingIntro {
      position: relative;
      padding-bottom: 45px;
      font-size: $font-size-large;
      @media (max-width: 767px) {
        font-size: $font-size-base;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
        background-size: 5px;
      }
      > * {
        max-width: 930px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .headerWrapper {
        display: none;
      }
      .contentBlockWrapper {
        margin-left: 0;
      }
    }
    .listContent {
      margin-top: 45px;
    }
  }
  // listing - donors list
  .listing .donorsListWrapper {
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    width: $container-grid-size;
    max-width: $container-max-width;
    .listingIntro {
      position: relative;
      padding-bottom: 45px;
      font-size: $font-size-large;
      @media (max-width: 767px) {
        font-size: $font-size-base;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
        background-size: 5px;
      }
      > * {
        max-width: 930px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .donorsList {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
  // if on post page without banner
  .noBanner {
    .contentBlockWrapper:not(.appealActionsWrapper) {
      margin: 80px auto;
      width: $container-grid-size;
      max-width: $post-max-width;
    }
    &.appealPostPage {
      .contentBlockWrapper:not(.appealActionsWrapper) {
        margin-top: 0;
      }
      .appealActionsWrapper {
        background: none;
        padding: 0;
        margin: 40px auto;
        width: $container-grid-size;
        max-width: $post-max-width;
        .button {
          margin: 0;
        }
      }
      .postAside {
        display: none;
      }
    }
  }
}

/* Breadcrumbs -------------------------------- */
.breadcrumbWrapper {
  a {
    color: $body-colour;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      color: $link-colour;
      text-decoration: underline;
    }
  }
  .breadcrumbList {
    margin-top: 0;
    margin-bottom: 0;
  }
  .breadcrumbText {
    display: none;
  }
  .breadcrumbItem {
    margin-bottom: 20px;
    margin-right: 0;
    font-size: 13.6px;
    &:before {
      content: "|";
      margin: 0 0.5em;
      font-size: 13.6px;
      color: $body-colour;
    }
  }
}

/* General Post Page -------------------------- */
.content {
  font-size: 18px;
  table {
    border-color: rgba($bg-light, 0.5);
    tr:nth-child(odd) {
      background: rgba($bg-light, 0.5);
    }
  }
  .mediaImage {
    border-radius: 10px;
  }
  // responsives
  @media (max-width: 767px) {
    font-size: $font-size-base;
  }
}
body.newsPostBody .post .postContent .postFooterWrapper .postFooter .publishDetailsWrapper {
  display: block;
  // "published:"
  strong {
    display: none;
  }
  // date itself
  time {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $event;
    sup {
      display: none;
    }
  }
  #updatedDateWrapper {
    display: none;
  }
  #publishAuthorWrapper {
    display: none;
  }
}

/* Share This Page ---------------------------- */
.shareWrapper {
  position: relative;
  padding-top: 20px;
  margin: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
    background-size: 5px;
  }
  > strong {
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;
  }
  .shareList {
    gap: 40px;
    a {
      justify-content: center;
    }
    li {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 0.5px;
        background: $brand-primary;
        transition: width $transition-duration $transition-curve;
      }
      a {
        transition: transform $transition-duration $transition-curve;
      }
      &:hover,
      &:focus {
        &:before {
          width: 100%;
        }
        a {
          transform: translateY(-9.5px);
        }
      }
    }
  }
}

/* Listing Post Card -------------------------- */
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
  background: $bg-colour;
  &:hover [class^="listed"][class$="Text"] .readMore:before{
    width: 100%;
  }
  // the image
  [class*="image-link"] {
    order: 1;
    min-height: 200px;
    border-radius: 6px;
    aspect-ratio: 41 / 20;
    .banner {
      width: 100%;
    }
  }
  // the event footer
  .postFooterWrapper {
    order: 2;
    font-size: 18px;
    font-weight: $font-weight-bold;
    p:before {
      display: none;
    }
    sup {
      display: none;
    }
    .locationDetails {
      display: none !important;
    }
    .postFooter > *:not(.button) {
      opacity: 1;
    }
  }
  // the text content
  [class^="listed"][class$="Text"] {
    order: 3;
    display: flex;
    flex-direction: column;
    // title
    h2 {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 45px;
        height: 4px;
        background: $brand-primary;
      }
      a {
        text-decoration: none;
      }
    }
    // summary
    .postSummary {
      flex: 1;
      gap: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    // read more
    .readMore {
      position: relative;
      display: block;
      margin: auto 0 0;
      padding: 0;
      color: $body-colour;
      font-weight: $font-weight-bold;
      width: fit-content;
      border: none;
      background: transparent;
      &:before {
        content: "";
        position: absolute;
        bottom: -2.5px;
        left: 0;
        width: 0;
        height: 2px;
        background: $brand-primary;
        transition: width $transition-duration $transition-curve;
      }
      &:after {
        content: "\f054";
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 10px;
        margin-left: 6px;
        transform: translateY(-1px);
      }
    }
  }
  // for news only listing
  body.news & .postFooterWrapper {
    display: block;
    .publishDatesWrapper {
      display: block;
    }
  }
}

/* Category Filter ---------------------------- */
.categoryFilterWrapper .categoryFilterContent {
  background: none;
  margin: 45px 0 0;
  padding: 0;
  gap: 20px;
  > h2 {
    margin-bottom: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    &:after {
      content: " by";
    }
  }
  // for single type filters
  .listedFilters {
    gap: 20px;
    li {
      margin: 0;
      padding: 13px 19px;
      border-radius: 500px;
      color: $body-colour;
      background: transparent;
      border: 1px solid $brand-primary;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      transition: background $transition-duration $transition-curve;
      &:focus,
      &:hover {
        background: $brand-primary;
      }
    }
  }
  .appliedFilters {
    padding: 0;
    background: none;
    > h3 {
      margin-bottom: 20px;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
    }
    li {
      margin: 0;
      padding: 13px 19px;
      border-radius: 500px;
      color: $white;
      background: $hover-primary;
      border: 1px solid $hover-primary;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      .remove {
        color: $white;
        background: $brand-primary;
        &:before {
          font-size: 12px;
        }
      }
    }
  }
  // for multiple type filters
  .groupedFilters {
    margin: 0;
    padding: 13px 19px;
    border-radius: 500px;
    color: $body-colour;
    background: transparent;
    border: 1px solid $brand-primary;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    transition: background $transition-duration $transition-curve;
    .filter input {
      transform: translateY(1px);
    }
    &:focus,
    &:hover {
      background: $brand-primary;
    }
  }
}

/* Blockquotes -------------------------------- */
blockquote {
  position: relative;
  &:before,
  &:after {
    content: "";
    left: 40px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  // quotes
  &:before {
    top: -25px;
    width: 50px;
    height: 50px;
    background-image: url($assets-path + "quotes.svg");

  }
  // triangle
  &:after {
    top: calc(100% - 1px);
    width: 33px;
    height: 21px;
    background-image: url($assets-path + "triangle.svg");
  }
} 

/* Donation Form ------------------------------ */
.donationForm {
  margin: 80px auto;
  width: $container-grid-size;
} 

/* Tabbing ------------------------------------ */
.tabsWrapper {
  width: $container-grid-size;
  max-width: $container-max-width;
  .tabs {
    border-color: $bg-colour;
    a {
      color: $body-colour;
      font-weight: $font-weight-semibold;
    }
  }
  .tab:not(.active) {
    background-color: $bg-colour;
    transition: background $transition-duration $transition-curve;
    &:hover {
      background-color: $brand-primary;
    }
  }
}

/* Fundraiser --------------------------------- */
.fundraisersHeader {
  // just to be sure
  background: none !important;
  // remove logo icon
  &:before {
    display: none;
  }
  .findAFundraiserWrapper {
    width: $container-grid-size;
    max-width: $container-max-width;
  }
  .findAFundraiser {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 45px;
    background-color: transparent;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: radial-gradient(circle at 1px 1px, $brand-primary 1px, transparent 0);
      background-size: 5px;
    }
  }
  .findAFundraiserForm {
    legend {
      font-weight: $font-weight-bold;
    }
    input {
      border-radius: 10px;
    }
  } 
}

/* FAQ ---------------------------------------- */
body[class*="faqs"] .faqListingPage .contentBlock .listContent {
  gap: 20px;
  .listedFaq {
    width: 100%;
    // when accordian is expanded
    &.accordianOpen .accordianHeader {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .buttonIcon {
        &:before {
          transform: translate(-50%, -50%) rotate(180deg) scale(0.5);
          opacity: 0;
        }
        &:after {
          transform: translate(-50%, -50%) rotate(0deg) scale(1);
          opacity: 1;
        }
      }
    }
    // the clickable accordian
    .accordianHeader {
      margin: 0;
      gap: 30px;
      display: flex;
      align-items: center;
      transition: background $transition-duration $transition-curve, border-radius $transition-duration $transition-curve;
      a {
        color: $white;
        text-decoration: none;
      }
      .accordianHeaderText {
        flex: 1;
        h2 {
          @media (max-width: 767px) {
            font-size: $font-mobile-h5;
          }
        }
      }
      .buttonIcon {
        &:before,
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          font-family: "FontAwesome";
          line-height: 1;
          font-weight: normal;
          color: $white;
          transition: transform $transition-duration $transition-curve, opacity $transition-duration $transition-curve;
        }
        &:before {
          content: "\f067";
          transform: translate(-50%, -50%) rotate(0deg) scale(1);
          opacity: 1;
        }
        &:after {
          content: "\f068";
          transform: translate(-50%, -50%) rotate(-180deg) scale(0.5);
          opacity: 0;
        }
      }
      &:hover {
        background-color: lighten($brand-primary, 10%);
      }
    }
    // content of accordian
    .accordianBody {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      .contentBlock {
        margin: 0;
        padding: 30px;
        background: $bg-colour;
        border-radius: 0 0 8px 8px;
      }
      // for some reason - full pages sometimes display on the body
      .headerWrapper {
        display: none;
      }
      .contentBlockWrapper {
        margin: 0;
        width: 100%;
        max-width: 100%;
        .contentBlock {
          padding: 0;
        }
      }
    }
    // custom faq banner
    .faqBanner {
      width: 80px;
      height: 80px;
      flex: 0 0 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  @media (max-width: 576px) {
    .listedFaq {
      .accordianHeader {
        gap: 15px;
      }
      .accordianBody .contentBlock {
        padding: 15px;
      }
      .faqBanner {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
      }
    }
  }
} 

/* Miscellaneous ------------------------------ */
// form page with no banner
#customFormContainer {
  padding-top: 5rem;
}

// 404 page
#pnlDefault404 {
  margin-top: calc(200px + 5rem);
}

// search page
.searchResultsArea .listedPost .readMore {
  display: block;
  margin-top: 30px;
  width: fit-content;
  text-decoration: none;
  a {
    transition: none;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

// popup - cirque clients module
.footerBox.Popup {
	display: none;
	width: 50vw;
	max-width: 650px;
	z-index: 1000;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  border: 10px solid rgba(#fff, .9);
  a {
    color: $brand-primary;
    text-decoration: underline;
  }
	.popup {
		padding: 60px;
		background-color: #fff;
	}
	.popup-title {
		color: $brand-primary;
		text-align: center;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 10px;
			padding: 0;
			color: inherit;
			font-size: 2.055555556rem;
			font-weight: 600;
		}
	}
	.popup-content {
		color: $body-colour;
		text-align: center;
		p {
			color: inherit;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.popup-close {
		&:before {
      content: "\f00d";
      font-family: "FontAwesome";
    }
		position: absolute;
		top: -10px;
		right: -10px;
		background-color: #fff;
		width: 40px;
		height: 40px;
		transform: translate(50%, -50%);
		border-radius: 50%;
		line-height: 40px;
		text-align: center;
		font-size: 1.11rem;
		cursor: pointer;
	}
}

body.popup-is-visible {
	position: relative;
	&::before {
		content: "";
		display: block;
		z-index: 500;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(#000, .6);
	}
	.footerBox.Popup {
		display: block;
	}
}

@media screen and (max-width: 768px){
	.footerBox.Popup {
		width: 75vw;
		.popup {
			padding: 40px 20px;
		}
		.popup-title {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 1.666666667rem;
			}
		}
	}
}
