// Global
$platform-name: "St Albans Cathedral";
$assets-path: "../assets/" ;

// Fonts - Display Type
@font-face {
    font-family: "Libre Baskerville";
    src: url($assets-path + "LibreBaskerville-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Libre Baskerville";
    src: url($assets-path + "LibreBaskerville-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
// Fonts - Body Type
@font-face {
    font-family: "Libre Franklin";
    src: url($assets-path + "LibreFranklin-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Libre Franklin";
    src: url($assets-path + "LibreFranklin-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Libre Franklin";
    src: url($assets-path + "LibreFranklin-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Libre Franklin";
    src: url($assets-path + "LibreFranklin-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Brand Colours
$brand-primary: #B49059;
$brand-secondary: #B51227;
$brand-tertiary: #4A4A49;
$brand-quarternary: #710E12;

// Hover Colours
$hover-primary: #886D43;
$hover-secondary: #710E12;
$hover-ghost: #B4915A;

// Background Palette
$bg-colour: #FAF8F5;
$bg-dark: #4A4A49;
$bg-light: #F2F2F2;
$bg-white: #FFFFFF;
$bg-black: #151515;

// Font Palette
$body-colour: #4D413F;
$headings-colour: #4D413F;
$link-colour: #B61228;
$donate-colour: $brand-primary;

// Other Colours
$white: #FFFFFF;
$black: #000000;
$focus: #1179C3;
$ashen: #2F2F2F;
$icons: #CEBB9F;
$event: #AB8854;

// Typography
$font-family-base: "Libre Franklin", sans-serif;
$display-font-family: "Libre Baskerville", sans-serif;

$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$line-height-base: 1.5;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font-weight: $font-weight-bold;
$displays-font-weight: $font-weight-bold;

$font-size-h1: 47.78px;
$font-size-h2: 39.81px;
$font-size-h3: 33.18px;
$font-size-h4: 27.65px;
$font-size-h5: 23.04px;
$font-size-h6: 19.20px;

$font-mobile-h1: 38.22px;
$font-mobile-h2: 31.85px;
$font-mobile-h3: 26.54px;
$font-mobile-h4: 22.12px;
$font-mobile-h5: 18.43px;

$font-display-1: 90px;
$font-display-2: 64px;
$font-display-3: 45px;
$font-display-4: 32px;

$mobile-display-1: 72.0px;
$mobile-display-2: 51.2px;
$mobile-display-3: 36.0px;
$mobile-display-4: 25.6px;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$post-max-width: 840px;
$smaller-max-width: 1440px;
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Logo
$logo: "logo.svg";
$logo-width: 174px;
$logo-height: 50px;
$logo-mobile-width: $logo-width * 0.8;
$logo-mobile-height: $logo-height * 0.8;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $ashen;
$btn-border-radius: 500px;
$btn-border-width: 1px;
$btn-padding-x: 25px;
$btn-padding-y: 13px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-hover-style: darken; // darken or lighten the button on hover

// Menu admin
$menu-admin-enabled: false;

// Header
$header-background-colour: $bg-black;

// Header content
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: 0;
$header-text-margin-bottom: 0;

// Search
$header-search-margin-right: 0;

// Search - input
$header-search-input-max-width: 100%;
$header-search-input-margin-right: 0;
$header-search-input-padding: 10px 0;
$header-search-input-placeholder-colour: $white;
$header-search-input-border-radius: 0;
$header-search-input-border-colour: transparent;
$header-search-input-border-width: 0; // t-r-b-l
$header-search-input-background-colour: transparent;
$header-search-input-colour: $white;
$header-search-input-focus-background-colour: transparent;
$header-search-input-focus-colour: $white;

// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 24px;
$header-search-button-icon-colour: $white;
$header-search-button-border-radius: 0;

// Navigation
$nav-type: flyout; // normal, flyout, fullscreen, mega, inline
$nav-background-colour: $bg-black;

// Top level items
$nav-top-level-item-colour: $white;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-width: 100%;
$nav-submenu-background-colour: transparent;
$nav-submenu-border-radius: 0;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-size: $font-size-large;
$nav-submenu-item-hover-colour: $white;
$nav-submenu-item-hover-background-colour: transparent;
$nav-submenu-item-padding: 10px 30px 10px 50px;

// Social icons
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-width: 28px;
$social-icons-height: 28px;
$social-icons-font-size: 28px;
$social-icons-gap: 0;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Footer
$footer-margin-y: 0;
$footer-padding-y: 0;
$footer-background-colour: $brand-tertiary;
$footer-colour: $white;
$footer-link-colour: $white;
$footer-link-decoration: none;
$footer-link-hover-colour: $white;
$footer-link-hover-decoration: none;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: false;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: false;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $white;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-margin-bottom: 30px;
$newsletter-input-background-colour: transparent;
$newsletter-button-height: auto; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $body-colour;
$newsletter-button-border-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 0;
$newsletter-hero-contents-max-width: 100%;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-breakpoint: 0;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: center; // left, center, right
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
$carousel-details-padding: 0;
$carousel-details-text-align: center;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-display-2;
$carousel-summary-enabled: false;
$carousel-read-more-enabled: false;

// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 50%;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-icon-colour: $white;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Home intro
$home-intro-background-colour: transparent;
$home-intro-content-max-width: 976px;
$home-intro-padding-y: 0;
$home-intro-margin-top: 90px;
$home-intro-margin-bottom: 90px;
$home-intro-text-align: left;
$home-intro-font-size: $font-size-base;
$home-intro-font-weight: $font-weight-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: 0;
$home-intro-mobile-padding-y: 0;
$home-intro-mobile-font-size: $font-size-base;

// Home features
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Impact stats
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-border-radius: 0;
$impact-stats-colour: $white;
$impact-stats-text-align: left; // left, center, right
$impact-stats-content-max-width: 100%;
$impact-stats-breakpoint: 767px; // When do you want to break the overall layout from row to column?

// Impact stats heading
$impact-stats-heading-enabled: false; //true or false

// Impact individual stat
$impact-stat-margin: 0;

// Impact stats figure
$impact-stats-figure-colour: $white;
$impact-stats-figure-font-size: $font-size-h1;

// Impact stats summary
$impact-stats-summary-colour: $white;

// Sidebar
$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: false;
$associated-most-read-enabled: false;

// Share this page
$share-label-font-size: $font-size-base;

// Page title
$page-title-font-size: $font-display-2;
$page-title-font-family: $display-font-family;
$page-title-colour: $body-colour;
$page-title-margin-top: 0;
$page-title-margin-bottom: 0;

// Card
$card-border-radius: 10px;
$card-border: none;
$card-image-border-radius: 6px;
$card-details-padding: 0;
$card-details-background-colour: transparent;
$card-heading-colour: $body-colour;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: 30px;
$card-summary-colour: $body-colour;
$card-footer-background-colour: transparent;
$card-footer-colour: $event;
$card-footer-padding: 0;

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.025;

// Listed post settings
$listed-post-read-more-enabled: true;

// Blockquote
$blockquote-border-radius: 10px;
$blockquote-margin-y: 40px;
$blockquote-padding: 40px;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-large; // Em unit will cascade to children
$blockquote-font-family: $font-family-base;
$blockquote-font-weight: $font-weight-semibold;
$blockquote-colour: $body-colour;

// FAQs
$faq-content-max-width: 100%;
$faq-item-header-background-colour: $brand-primary;
$faq-item-header-padding: 30px;
$faq-item-header-border-radius: 8px;
$faq-item-header-font-size: $font-size-h5;
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $white;
$faq-item-hover-btn-background-colour: transparent;
$faq-item-hover-btn-chevron-colour: $white;
